import { TFunction } from 'i18next'
import { LogoutReason, UserRoleEnum } from '@api/endpoints/auth'
import { UserLanguageEnum } from '@model/language'
import { FinancialObligationTypeEnum, TopicEnum } from '@api/endpoints/forms'
import {BodyTypeEnum, FuelTypeEnum, VehicleSortByEnum, WarrantyTypeEnum} from '@api/endpoints/vehicles'

// Authentication

export const languageTranslation =
  (t: TFunction) =>
  (lang: UserLanguageEnum): string => {
    switch (lang) {
      case 'et':
        return t('enums.language.et')
      case 'ru':
        return t('enums.language.ru')
      case 'en':
        return t('enums.language.en')
    }
  }

export const roleTranslation =
  (t: TFunction) =>
  (role: UserRoleEnum): string => {
    switch (role) {
      case 'ADMIN':
        return t('enums.userRoles.Admin')
      case 'USER':
        return t('enums.userRoles.User')
    }
  }

export const logoutReasonTranslation =
  (t: TFunction) =>
  (reason: LogoutReason): string => {
    switch (reason) {
      case 'InvalidLogin':
        return t('enums.logoutReasons.InvalidLogin')
      case 'SignedOut':
        return t('enums.logoutReasons.SignedOut')
      case 'TimedOut':
        return t('enums.logoutReasons.TimedOut')
    }
  }

// vehicles

export const bodyTypeTranslation =
  (t: TFunction) =>
  (value: BodyTypeEnum): string => {
    switch (value) {
      case 'TRAILER':
        return t('enums.bodyType.TRAILER')
      case 'CARAVAN':
        return t('enums.bodyType.CARAVAN')
      case 'CABRIOLET':
        return t('enums.bodyType.CABRIOLET')
      case 'COMMERCIAL_VEHICLE':
        return t('enums.bodyType.COMMERCIAL_VEHICLE')
      case 'COUPE':
        return t('enums.bodyType.COUPE')
      case 'HATCHBACK':
        return t('enums.bodyType.HATCHBACK')
      case 'MINIVAN':
        return t('enums.bodyType.MINIVAN')
      case 'MOTO':
        return t('enums.bodyType.MOTO')
      case 'PICKUP':
        return t('enums.bodyType.PICKUP')
      case 'SUV':
        return t('enums.bodyType.SUV')
      case 'SEDAN':
        return t('enums.bodyType.SEDAN')
      case 'TOURING':
        return t('enums.bodyType.TOURING')
      case 'JET':
        return t('enums.bodyType.JET')
    }
  }

export const fuelTypeTranslation =
  (t: TFunction) =>
  (value: FuelTypeEnum): string => {
    switch (value) {
      case 'DIESEL':
        return t('enums.fuelType.DIESEL')
      case 'PETROL':
        return t('enums.fuelType.PETROL')
      case 'ELECTRIC':
        return t('enums.fuelType.ELECTRIC')
      case 'HYBRID':
        return t('enums.fuelType.HYBRID')
      case 'PLUGIN':
        return t('enums.fuelType.PLUGIN')
      case 'OTHER':
        return t('enums.fuelType.OTHER')
    }
  }

// forms

export const topicTranslation =
  (t: TFunction) =>
  (value: TopicEnum): string => {
    switch (value) {
      case 'leasing':
        return t('enums.topics.leasing')
      case 'installment':
        return t('enums.topics.installment')
      case 'exchange':
        return t('enums.topics.exchange')
      case 'buyback':
        return t('enums.topics.buyback')
      case 'commission':
        return t('enums.topics.commission')
      case 'guarantee':
        return t('enums.topics.guarantee')
      case 'other':
        return t('enums.topics.other')
    }
  }

/*
export const civilStatusTranslation =
  (t: TFunction) =>
  (value: CivilStatusTypeEnum): string => {
    switch (value) {
      case 'SINGLE':
        return t('pages.leasing.personalData.fields.civilStatus.single')
      case 'MARRIED':
        return t('pages.leasing.personalData.fields.civilStatus.married')
      case 'DIVORCED':
        return t('pages.leasing.personalData.fields.civilStatus.divorced')
      case 'COMMON_LAW_MARRIAGE':
        return t('pages.leasing.personalData.fields.civilStatus.common_law_marriage')
      case 'WIDOW':
        return t('pages.leasing.personalData.fields.civilStatus.widow(er)')
    }
  }

export const educationTypeTranslation =
  (t: TFunction) =>
  (value: EducationTypeEnum): string => {
    switch (value) {
      case 'HIGHER':
        return t('pages.leasing.personalData.fields.education.higher')
      case 'PRIMARY':
        return t('pages.leasing.personalData.fields.education.primary')
      case 'SECONDARY':
        return t('pages.leasing.personalData.fields.education.secondary')
      case 'VOCATIONAL':
        return t('pages.leasing.personalData.fields.education.vocational')
      case 'COLLEGE':
        return t('pages.leasing.personalData.fields.education.college')
    }
  }

export const occupationTranslation =
  (t: TFunction) =>
  (value: OccupationTypeEnum): string => {
    switch (value) {
      case 'EXECUTIVE':
        return t('pages.leasing.personalData.fields.occupation.executive')
      case 'WORKER':
        return t('pages.leasing.personalData.fields.occupation.worker')
      case 'SPECIALIST_OR_OFFICE_WORKER':
        return t('pages.leasing.personalData.fields.occupation.specialistOrOfficeWorker')
      case 'SUPERIOR_SPECIALIST':
        return t('pages.leasing.personalData.fields.occupation.superiorSpecialist')
      case 'MIDDLE_MANAGER':
        return t('pages.leasing.personalData.fields.occupation.middleManager')
      case 'OWNER':
        return t('pages.leasing.personalData.fields.occupation.owner')
      case 'STUDENT':
        return t('pages.leasing.personalData.fields.occupation.student')
      case 'PENSIONER':
        return t('pages.leasing.personalData.fields.occupation.pensioner')
      case 'AT_HOME':
        return t('pages.leasing.personalData.fields.occupation.atHome')
      case 'UNEMPLOYED':
        return t('pages.leasing.personalData.fields.occupation.unemployed')
      case 'PRIVATE_ENTREPRENEUR':
        return t('pages.leasing.personalData.fields.occupation.privateEntrepreneur')
    }
  }

export const timeEmployedTranslation =
  (t: TFunction) =>
  (value: TimeEmployedEnum): string => {
    switch (value) {
      case 'TRIAL':
        return t('pages.leasing.personalData.fields.timeEmployed.trial')
      case 'UP_TO_1':
        return t('pages.leasing.personalData.fields.timeEmployed.upTo1')
      case 'UP_TO_2':
        return t('pages.leasing.personalData.fields.timeEmployed.upTo2')
      case 'UP_TO_3':
        return t('pages.leasing.personalData.fields.timeEmployed.upTo3')
      case 'UP_TO_4':
        return t('pages.leasing.personalData.fields.timeEmployed.upTo4')
      case 'UP_TO_5':
        return t('pages.leasing.personalData.fields.timeEmployed.upTo5')
      case 'MORE_THAN_5':
        return t('pages.leasing.personalData.fields.timeEmployed.moreThan5')
    }
  }

export const supportedPersonsTranslation =
  (t: TFunction) =>
  (value: SupportedPersonsEnum): string => {
    switch (value) {
      case 'ZERO':
        return t('pages.leasing.personalData.fields.supportedPersons.enum.zero')
      case 'ONE':
        return t('pages.leasing.personalData.fields.supportedPersons.enum.one')
      case 'TWO':
        return t('pages.leasing.personalData.fields.supportedPersons.enum.two')
      case 'THREE':
        return t('pages.leasing.personalData.fields.supportedPersons.enum.three')
      case 'FOUR_OR_MORE':
        return t('pages.leasing.personalData.fields.supportedPersons.enum.four_or_more')
    }
  }

export const leasingTypeTranslation =
  (t: TFunction) =>
  (value: LeasingTypeEnum): string => {
    switch (value) {
      case 'FINANCIAL':
        return t('pages.leasing.application.fields.typeOfLeasing.financial')
      case 'OPERATIONAL':
        return t('pages.leasing.application.fields.typeOfLeasing.operational')
    }
  }
*/

export const obligationTypeTranslation =
  (t: TFunction) =>
  (value: FinancialObligationTypeEnum): string => {
    switch (value) {
      case 'HOME_LOAN':
        return t('global.forms.obligations.obligationType.homeLoan')
      case 'CONSUMER_LOAN':
        return t('global.forms.obligations.obligationType.consumerLoan')
      case 'CAR_LOAN':
        return t('global.forms.obligations.obligationType.homeLoan')
      case 'FACTORING':
        return t('global.forms.obligations.obligationType.consumerLoan')
      case 'PAYMENT_BY_INSTALLMENTS':
        return t('global.forms.obligations.obligationType.paymentByInstallments')
      case 'STUDENT_LOAN':
        return t('global.forms.obligations.obligationType.studentLoan')
      case 'OVERDRAFT':
        return t('global.forms.obligations.obligationType.overdraft')
      case 'CREDIT_CARD':
        return t('global.forms.obligations.obligationType.creditCard')
      case 'GUARANTEE':
        return t('global.forms.obligations.obligationType.guarantee')
      case 'ALIMONY':
        return t('global.forms.obligations.obligationType.alimony')
      case 'ARREARS_OF_TAXES':
        return t('global.forms.obligations.obligationType.arrearsOfTaxes')
    }
  }

export const warrantyTypeTranslation =
  (t: TFunction) =>
  (value: WarrantyTypeEnum): string => {
    switch (value) {
      case 'CARPROF_PREMIUM':
        return t('enums.warranty-type-enum.CARPROF_PREMIUM')
      case 'CARPROF_STANDARD':
        return t('enums.warranty-type-enum.CARPROF_STANDARD')
      case 'CARPROF_STANDARD_PLUS':
        return t('enums.warranty-type-enum.CARPROF_STANDARD_PLUS')
      case 'FACTORY':
        return t('enums.warranty-type-enum.FACTORY')
      case 'NONE':
        return t('enums.warranty-type-enum.NONE')
    }
  }

  export const vehicleSortByTranslation = (t: TFunction) => (value: VehicleSortByEnum): string => {
    switch (value) {
      case 'YEAR':
        return t('enums.vehiclesSortBy.YEAR')
      case 'PRICE':
        return t('enums.vehiclesSortBy.PRICE')
      case 'MILEAGE':
        return t('enums.vehiclesSortBy.MILEAGE')
      case 'NEWLY_ADDED':
        return t('enums.vehiclesSortBy.NEWLY_ADDED')
    }
  }
