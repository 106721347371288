import * as React from 'react'
import { KeyboardArrowUp } from '@mui/icons-material'
import { Fab, Zoom, useScrollTrigger } from '@mui/material'
import styles from './ScrollToTop.module.css'

export type Props = Readonly<{
  id: string
}>

export const ScrollToTop: React.FC<Props> = ({ id }) => {
  const target = document.getElementById(id) ?? undefined

  const trigger = useScrollTrigger({
    target: target,
    disableHysteresis: true,
    threshold: 100
  })

  const onClickHandler = (): void => {
    target?.scrollTo(0, 0)
  }

  return (
    <Zoom in={trigger}>
      <div className={styles.scrollToTopContainer} role="presentation">
        <Fab onClick={onClickHandler} color="primary" aria-label="Scroll back to top">
          <KeyboardArrowUp />
        </Fab>
      </div>
    </Zoom>
  )
}
