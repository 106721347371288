import * as React from 'react'
import { NullableType, isEmpty } from '@digital-magic/ts-common-utils'
import { Menu, MenuProps } from '@mui/material'

export type MenuActionHandler = React.Dispatch<HTMLElement>

export type MenuRenderMethod = (props: {
  openHandler: MenuActionHandler
  closeHandler: MenuActionHandler
}) => React.ReactNode

type Props = //React.PropsWithChildren<ChildrenType> &
  Omit<MenuProps, 'open' | 'onClose' | 'anchorEl' | 'children'> & {
    children: MenuRenderMethod
    renderMenu: MenuRenderMethod
  }

export const MenuTrigger: React.FC<Props> = ({ children, renderMenu, ...menuProps }) => {
  const [anchorMenu, setAnchorMenu] = React.useState<NullableType<HTMLElement>>(null)

  const closeHandler: React.DispatchWithoutAction = () => {
    setAnchorMenu(null)
  }

  const openHandler: MenuActionHandler = (el) => {
    setAnchorMenu(el)
  }

  React.useEffect(() => {
    // if menu is open, add event listener to track screen width changes and close menu
    if (!isEmpty(anchorMenu)) {
      window.addEventListener('resize', closeHandler)
    }
    // Clean up by removing event listener when component unmounts
    return () => {
      window.removeEventListener('resize', closeHandler)
    }
  }, [anchorMenu])

  return (
    <>
      {children({ openHandler, closeHandler })}
      <Menu
        anchorEl={anchorMenu}
        open={Boolean(anchorMenu)}
        onClose={closeHandler}
        //TransitionComponent={Fade}
        //disableScrollLock
        {...menuProps}
      >
        {renderMenu({ openHandler, closeHandler })}
      </Menu>
    </>
  )
}
