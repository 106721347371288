import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { LogoutReason, UserRoleEnum } from '@api/endpoints/auth'
import {BodyTypeEnum, FuelTypeEnum, VehicleSortByEnum, WarrantyTypeEnum} from '@api/endpoints/vehicles'
import { FinancialObligationTypeEnum, TopicEnum } from '@api/endpoints/forms'
import { UserLanguageEnum } from '@model/language'
import {
  fuelTypeTranslation,
  languageTranslation,
  logoutReasonTranslation,
  obligationTypeTranslation,
  roleTranslation,
  topicTranslation,
  bodyTypeTranslation,
  warrantyTypeTranslation, vehicleSortByTranslation
} from './utils'

export type TranslationFn<T> = (value: T) => string

type HookResult = {
  languageTranslation: TranslationFn<UserLanguageEnum>
  roleTranslation: TranslationFn<UserRoleEnum>
  logoutReasonTranslation: TranslationFn<LogoutReason>
  bodyTypeTranslation: TranslationFn<BodyTypeEnum>
  fuelTypeTranslation: TranslationFn<FuelTypeEnum>
  topicTranslation: TranslationFn<TopicEnum>
  /*
  civilStatusTranslation: TranslationFn<CivilStatusTypeEnum>
  educationTypeTranslation: TranslationFn<EducationTypeEnum>
  occupationTranslation: TranslationFn<OccupationTypeEnum>
  timeEmployedTranslation: TranslationFn<TimeEmployedEnum>
  supportedPersonsTranslation: TranslationFn<SupportedPersonsEnum>
  leasingTypeTranslation: TranslationFn<LeasingTypeEnum>
  */
  obligationTypeTranslation: TranslationFn<FinancialObligationTypeEnum>
  warrantyTypeTranslation: TranslationFn<WarrantyTypeEnum>
  vehiclesSortByTranslation: TranslationFn<VehicleSortByEnum>
}

export const useEnumTranslation = (): HookResult => {
  const { t } = useTranslation()

  return React.useMemo(
    () => ({
      languageTranslation: languageTranslation(t),
      roleTranslation: roleTranslation(t),
      logoutReasonTranslation: logoutReasonTranslation(t),
      bodyTypeTranslation: bodyTypeTranslation(t),
      fuelTypeTranslation: fuelTypeTranslation(t),
      topicTranslation: topicTranslation(t),
      /*
      civilStatusTranslation: civilStatusTranslation(t),
      educationTypeTranslation: educationTypeTranslation(t),
      occupationTranslation: occupationTranslation(t),
      timeEmployedTranslation: timeEmployedTranslation(t),
      supportedPersonsTranslation: supportedPersonsTranslation(t),
      leasingTypeTranslation: leasingTypeTranslation(t),
      */
      obligationTypeTranslation: obligationTypeTranslation(t),
      warrantyTypeTranslation: warrantyTypeTranslation(t),
      vehiclesSortByTranslation: vehicleSortByTranslation(t)
    }),
    [t]
  )
}
