import * as React from 'react'
import { AuthContextResult } from './types'
import { AuthContext } from './AuthContext'

export const useAuthContext = (): AuthContextResult => {
  const context = React.useContext(AuthContext)
  if (context === undefined) {
    // eslint-disable-next-line functional/no-throw-statements
    throw new Error('useAuthContext must be used within a AuthContextProvider')
  }
  return context
}
